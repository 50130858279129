import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/ImageGatsby'
import TitleDefault from 'components/elements/TitleDefault'

const Content = styled(ParseContent)`
  & p {
    @media (min-width: 576px) {
      font-size: ${(props) => props.theme.font.size[18]};
    }
    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size[14]};
      text-align: center;
    }
  }
`

const StyledImage = styled(Image)`
  height: 400px !important;
  object-fit: cover !important;
`

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
}

const Team: React.FC<TeamProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <div className="row py-lg-5">
        <TitleDefault>Team</TitleDefault>
        <div className="d-flex flex-wrap mt-4">
          {fields.member?.map((member: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="col-6 mb-4 pb-lg-2 col-md-4" key={index}>
              <StyledImage image={member?.image} loading="lazy" />
              <Content content={member?.description} className="mt-3" />
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
)

export default Team
